<template>
  <div id="login" class="Main-Wrap TF">
    <div class="B-main">
      <div class="B-login-main">
        <div class="B-logo">
          <img src="../../public/images/Logo.png" alt="Logo" />
        </div>

        <div class="B-form">
          <v-text-field
            prepend-inner-icon="mdi-account"
            :rules="rules"
            label="Username"
            v-model="username"
            class="input-group--focused T-input"
          ></v-text-field>

          <v-text-field
            class="ColorTheme-S--border T-input"
            v-model="password"
            prepend-inner-icon="mdi-key"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            label="Password"
            :type="show1 ? 'text' : 'password'"
            @click:append="show1 = !show1"
            @keyup.enter="onLoginClick()"
          ></v-text-field>
        </div>
        Vr. 1
        <div class="B-btn">
          <v-btn
            text
            class="ColorTheme-S white--text center btn-size theme-btn"
            @click="onLoginClick()"
          >Login</v-btn>
        </div>
      </div>
    </div>
    <div id="footer">
      <div class="container">
        <div class="copy">Copyright © 2020 United Offshore Aviation Co.,Ltd.</div>
      </div>
    </div>
  </div>
</template>
<script>
import feathersClientUOA from "../plugins/feathers-client-uoa";
export default {
  name: "App",
  data() {
    return {
      showPassword: false,
      username: "",
      password: "",
      submitted: false,
      loading: false,
      show1: false,
      rules: {
        required: value => !!value || "Required."
      },
    };
  },
  methods: {
    async onLoginClick() {
      alert("asdas");
      this.loading = true;
      try {
        const response = await feathersClientUOA.authenticate({
          strategy: "local",
          u_username: this.username,
          u_password: this.password
        });
        
        const q = {};
        q.retire = 0
        q.u_id = response.account.u_id;

        var res = await feathersClientUOA.service("viewaccount").find({ query: q });
        localStorage.setItem("user", JSON.stringify(res.data[0]));
        //localStorage.setItem("user", JSON.stringify(response.user));
        
        this.$router.push("/home");
      } catch (error) {
        alert("login ไม่สำเร็จ [" + error.message + "]");
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
<style scoped>
div#contentMain {
    width: 100% !important;
}
</style>